.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  margin:3em;
}

.Donate-logo {
  width: 4em;
  padding: 0.5em;
}

.title {
  padding: 1em;
}

.donation {
  padding: 5%;
}

.messsage-space {
  height:60%;
}

.App-header {
  background-color: #4a43a4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
